import React, { FC, useState } from 'react';
import DatePicker from 'react-datepicker';

interface Props {
	date: Date | null;
	setDate: (date: Date) => void;
	isDisabled?: boolean;
}

export const SingleDate: FC<Props> = ({ date, setDate, isDisabled }) => {
	const [selectedDate, selectDate] = useState(date);

	const handleStartDate = (date: Date) => {
		selectDate(date);
		setDate(date);
	};

	return (
		<div className={`one_line_form single_date_V ${isDisabled ? 'disabled' : ''}`}>
			<DatePicker
				selected={selectedDate}
				onChange={handleStartDate}
				dateFormat="dd.MM.yy"
				calendarClassName={'date-and-time'}
			/>
		</div>
	);
};
