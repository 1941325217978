import { axiosInstance } from './../config';
import { id } from '../../types/general';

import { sports } from './sports';
import { categories } from './categories';
import { tournaments } from './tournaments';
import { competitors } from './competitors';
import { events } from './events';
import { auto } from './auto';
import { lms } from './lms';

export const mappings = {
	import(type: string, providerId: string, providerItemId: string) {
		return axiosInstance.post(`/mappings/import/${providerId}/data/${type}`, { providerItemId: providerItemId });
	},
	link(
		type: string,
		providerId: id,
		localItemId: id,
		providerItemId: id,
		idProviderTournament?: string,
		idProviderHome?: string,
		idProviderAway?: string
	) {
		return axiosInstance.post(`/mappings/map/${providerId}/data/${type}`, {
			localItemId: localItemId,
			providerItemId: providerItemId,
			idProviderTournament,
			idProviderHome,
			idProviderAway,
		});
	},
	unpair(type: string, providerId: id, providerItemId: id, isLive: boolean = false) {
		const encodedId = encodeURIComponent(encodeURIComponent('' + providerItemId));
		return axiosInstance.delete(
			`/mappings/map/${providerId}/data/${type}/${encodedId}${type === 'events' ? `?live=${isLive}` : ''}`
		);
	},
	sports,
	categories,
	tournaments,
	competitors,
	events,
	auto,
	lms,
};
