import { axiosInstance } from './../config';
import { id } from '../../types/general';
import { Item, SuggestedImportItem, LocalisationMap } from '../../pages/pairing/types';

interface Params {
	sportId?: string;
	providerId?: string;
	categoryId?: string;
	tournamentId?: string;
	live?: boolean;
	booked?: boolean;
	utcScheduledFrom?: Date;
	utcScheduledTo?: Date;
}
interface PlayersParams {
	localParentId: string;
	specialTournamentId: string;
	playersIds: string[];
}

export type IdPair = {
	idInternal: string;
	idExternal: string;
};

type SuggestedMappingsDTO = {
	localData: Item[];
	providerData: Item[];
	suggestionsMap: IdPair[];
	suggestedImport: SuggestedImportItem[];
	localisationMap: LocalisationMap;
	alwaysIncludeImport: boolean;
};
const getSuggestedMappings = (providerId: id, data: any): Promise<SuggestedMappingsDTO> => {
	return axiosInstance.post(`/mappings/suggested_mappings/${providerId}`, data).then(({ data }) => data);
};

type PairEventsDTO = { live: boolean; suggestionsMap: IdPair[] };
const pairEvents = (providerId: id, data: PairEventsDTO) => {
	return axiosInstance.post(`/mappings/batch_mapping/${providerId}`, data);
};

const importEvents = (providerId: id, data: { live: boolean; suggestedImport: SuggestedImportItem[] }) => {
	return axiosInstance.post(`/mappings/batch_import/${providerId}`, data);
};

export const auto = {
	getSuggestedMappings,
	pairEvents,
	importEvents,
};
