import React from 'react';
import { useTranslation } from 'react-i18next';
import SidebarDropdown from './SidebarDropdown';
import SidebarLink from './SidebarLink';

export default () => {
	const { t } = useTranslation();

	return (
		<SidebarDropdown icon="link" path="/pairing" text={t('general.pairing')}>
			<SidebarLink to="/pairing/events" icon="circle-medium">
				{t('eventsPairing.shortTitle')}
			</SidebarLink>

			<SidebarLink to="/pairing/prematch-events" icon="circle-medium">
				{t('eventsPairing.prematchPairing')}
			</SidebarLink>

			<SidebarLink to="/pairing/auto-mapping" icon="circle-medium">
				{t('eventsPairing.autoMapping')}
			</SidebarLink>

			<SidebarLink to="/pairing/tournaments" icon="circle-medium">
				{t('tournamentsPairing.shortTitle')}
			</SidebarLink>
		</SidebarDropdown>
	);
};
