import React, { lazy, Suspense } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { SimpleLoader as Loader } from '../../components/general/Loader';

// Import utils
import history from '../history';
import WrappedRoute from './WrappedRoute';

// Import context
import { useUser } from '../../context/UserProvider';

// Import layouts
import { MainLayout } from '../../layouts';
import { OnlineUser } from '../../pages/online-user';

const Dashboard = lazy(() => import('../../pages/Dashboard'));

// Administrations
const Events = lazy(() => import('../../pages/administration/Events'));
const MatchController = lazy(() => import('../../pages/administration/live/IndexLmcContext'));
const Competitors = lazy(() => import('../../pages/administration/competitors'));
const Tournaments = lazy(() => import('../../pages/administration/tournaments'));
const Categories = lazy(() => import('../../pages/administration/categories'));
const Sports = lazy(() => import('../../pages/administration/sports'));
const Markets = lazy(() => import('../../pages/administration/Markets'));
const Translations = lazy(() => import('../../pages/administration/Translations'));
const UsersAdministration = lazy(() => import('../../pages/internet/users'));
const CashFlow = lazy(() => import('../../pages/reports/cash-flow'));
const BasicFlow = lazy(() => import('../../pages/reports/basic-report'));
const Reservations = lazy(() => import('../../pages/reports/reservations'));
const Verification = lazy(() => import('../../pages/reports/verification'));
const BonusesAdministration = lazy(() => import('../../pages/internet/bonuses'));
const Transactions = lazy(() => import('../../pages/internet/transactions'));
const Players = lazy(() => import('../../pages/administration/players'));
const SpecialMarkets = lazy(() => import('../../pages/administration/special-markets'));
const Currencies = lazy(() => import('../../pages/administration/currencies'));
const Themes = lazy(() => import('../../pages/administration/themes'));
const Banners = lazy(() => import('../../pages/administration/banners'));
const externalApplications = lazy(() => import('../../pages/administration/externalApplications'));
const applicationType = lazy(() => import('../../pages/administration/applicationType'));
const Promotions = lazy(() => import('../../pages/administration/promotions'));
const Regulations = lazy(() => import('../../pages/administration/regulations'));

const EmailAdministration = lazy(() => import('../../pages/internet/email-service'));
const TemplatesAdministrations = lazy(() => import('../../pages/online-support/templates'));

// Internet
const VoucherSeries = lazy(() => import('../../pages/internet/vouchers'));
import { Vouchers } from '../../pages/internet/vouchers/components/voucher';
const SystemNotifications = lazy(() => import('../../pages/internet/system-notifications'));
const ReferralAndAffiliate = lazy(() => import('../../pages/internet/referral-affiliate'));

//Prematch offer
const PrematchController = lazy(() => import('../../pages/prematch/controller'));
const PrematchCreateEventsManually = lazy(() => import('../../pages/prematch/create-events-manually'));

// Pairing
const TournamentsPairing = lazy(() => import('../../pages/pairing/tournaments/index'));
const EventsPairing = lazy(() => import('../../pages/pairing/events-pairing/index'));
const PrematchEventsPairing = lazy(() => import('../../pages/pairing/prematch-events/index'));
const AutomatedEventsPairing = lazy(() => import('../../pages/pairing/auto-pairing/index'));

const LiveEventsPairing = lazy(() => import('../../pages/pairing/live-events'));
const OffersPlan = lazy(() => import('../../pages/OffersPlan'));
const LMSPairing = lazy(() => import('../../pages/pairing/lms-pairing/index'));

// Lists
const Tables = lazy(() => import('../../pages/Lists/Tables'));
const Builder = lazy(() => import('../../pages/Lists/builder'));
const Templates = lazy(() => import('../../pages/Lists/templates'));
const PrintAdministration = lazy(() => import('../../pages/Lists/print-administration'));
const ExportSpecials = lazy(() => import('../../pages/Lists/export-specials'));
const DailyOffer = lazy(() => import('../../pages/Lists/daily-offer'));
const TimeOffer = lazy(() => import('../../pages/Lists/daily-offer/time-offer'));

// Risk management
const Risk = lazy(() => import('../../pages/risk-management/controller'));
const RiskCategories = lazy(() => import('../../pages/risk-management/categories'));
const RiskTags = lazy(() => import('../../pages/risk-management/tags'));
const RiskGroups = lazy(() => import('../../pages/risk-management/risk-groups'));

// Results
const Results = lazy(() => import('../../pages/results'));
const PlayerPropsResults = lazy(() => import('../../pages/playerPropsResults'));

// System
const SystemIntegrations = lazy(() => import('../../pages/system/integrations'));
const RolesTemplates = lazy(() => import('../../pages/system/roles'));
const BackofficeUsers = lazy(() => import('../../pages/system/users'));
const Logging = lazy(() => import('../../pages/system/logging'));
const Settings = lazy(() => import('../../pages/system/settings'));

const TicketView = lazy(() => import('../../pages/reports/tickets'));
const EventStats = lazy(() => import('../../pages/reports/event-stats'));
const PromotionsReports = lazy(() => import('../../pages/reports/promotions-report'));
const BonusTips = lazy(() => import('../../pages/prematch/bonus-tips'));
const PaymentTransactions = lazy(() => import('../../pages/reports/payment-transactions'));
const TicketsReport = lazy(() => import('../../pages/reports/tickets-report'));
const TransactionsReport = lazy(() => import('../../pages/reports/transactions'));
const OnlineReport = lazy(() => import('../../pages/reports/online-report'));
const AMLReport = lazy(() => import('../../pages/reports/aml'));

// Parameters
const Conditions = lazy(() => import('../../pages/parameters/conditions'));
const Limits = lazy(() => import('../../pages/parameters/limits'));
const AuthorizationParams = lazy(() => import('../../pages/parameters/authorization'));

// Online support
const OnlineSupportUsers = lazy(() => import('../../pages/online-support/users'));

// Betmedia
const Unassigned = lazy(() => import('../../pages/betmedia/unassigned'));
const Devices = lazy(() => import('../../pages/betmedia/devices'));

// Geo
const Geo = lazy(() => import('../../pages/parameters/geo'));

// Cashout
const Cashout = lazy(() => import('../../pages/cashout-management/controller'));
const CashoutOffer = lazy(() => import('../../pages/cashout-management/offer'));
const CashoutTickets = lazy(() => import('../../pages/cashout-management/tickets'));

export default () => {
	return (
		<Router history={history}>
			<MainLayout>
				<Suspense fallback={<Loader />}>
					<Switch>
						<Route path="/" exact={true} component={Dashboard} />

						{/* Administration */}
						<WrappedRoute path="/administration/events" component={Events} />
						<WrappedRoute path="/administration/competitors" component={Competitors} />
						<WrappedRoute path="/administration/tournaments" component={Tournaments} />
						<WrappedRoute path="/administration/categories" component={Categories} />
						<WrappedRoute path="/administration/sports" component={Sports} />
						<WrappedRoute path="/administration/markets" component={Markets} />
						<WrappedRoute path="/administration/translations" component={Translations} />
						<WrappedRoute path="/administration/players" component={Players} />
						<WrappedRoute path="/administration/special-markets" component={SpecialMarkets} />
						<WrappedRoute path="/administration/externalApplications" component={externalApplications} />
						<WrappedRoute path="/administration/applicationType" component={applicationType} />
						<WrappedRoute path="/administration/promotions" component={Promotions} />
						<WrappedRoute path="/administration/regulations" component={Regulations} />
						<WrappedRoute path="/administration/currencies" component={Currencies} />
						<WrappedRoute path="/administration/themes" component={Themes} />
						{/* Internet */}
						<WrappedRoute subject="online" path="/internet/users" component={UsersAdministration} />
						<WrappedRoute subject="online" path="/internet/transactions" component={Transactions} />
						<WrappedRoute subject="online" path="/internet/bonuses" component={BonusesAdministration} />
						<WrappedRoute path="/internet/user/:uid/:page" component={OnlineUser} bypassCasl />
						<WrappedRoute subject="online" path="/internet/voucher-series" component={VoucherSeries} />
						<WrappedRoute subject="online" path="/internet/email-service" component={EmailAdministration} />
						<WrappedRoute path="/internet/vouchers/:id_series/:status_series" component={Vouchers} />
						<WrappedRoute path="/internet/system-notifications" component={SystemNotifications} />
						<WrappedRoute path="/internet/referral-affiliate" component={ReferralAndAffiliate} />
						{/* Prematch Offer */}
						<WrappedRoute subject="prematch" path="/prematch/controller" component={PrematchController} />
						<WrappedRoute subject="prematch" path="/prematch/bonus-tips" component={BonusTips} />
						<WrappedRoute
							subject="prematch"
							path="/prematch/create-events-manually"
							component={PrematchCreateEventsManually}
						/>
						{/* Live  */}
						<WrappedRoute subject="live" path="/live/administration" component={MatchController} />
						{/* Pairing */}
						<WrappedRoute path="/live/pairing" component={LiveEventsPairing} />
						<WrappedRoute path="/pairing/events" component={EventsPairing} />
						<WrappedRoute path="/pairing/prematch-events" component={PrematchEventsPairing} />
						<WrappedRoute path="/pairing/auto-mapping" component={AutomatedEventsPairing} />
						<WrappedRoute path="/pairing/tournaments" component={TournamentsPairing} />
						<WrappedRoute path="/live/lms-pairing" component={LMSPairing} />
						{/* Plan Offers */}
						<WrappedRoute subject="prematch" path="/offers-plan" component={OffersPlan} />
						{/* Lists */}
						<WrappedRoute subject="prematch" path="/lists/tables" component={Tables} />
						<WrappedRoute subject="prematch" path="/lists/templates" component={Templates} />
						<WrappedRoute subject="prematch" path="/lists/builder" component={Builder} />
						<WrappedRoute subject="prematch" path="/lists/print-administration" component={PrintAdministration} />
						<WrappedRoute subject="prematch" path="/lists/export-specials" component={ExportSpecials} />
						<WrappedRoute subject="prematch" path="/lists/daily-offer" component={DailyOffer} />
						<WrappedRoute subject="prematch" path="/lists/time-offer" component={TimeOffer} />
						{/* Risk */}
						<WrappedRoute subject="risk" path="/risk/controll" component={Risk} />
						{/* <WrappedRoute subject="risk" path="/risk/categories" component={RiskCategories} /> */}
						<WrappedRoute subject="risk" path="/risk/tags" component={RiskTags} />
						<WrappedRoute subject="risk" path="/risk/risk-groups" component={RiskGroups} />
						{/* Cashout */}
						<WrappedRoute subject="risk" path="/cashout/control" component={Cashout} />
						<WrappedRoute subject="risk" path="/cashout/offer" component={CashoutOffer} />
						<WrappedRoute subject="risk" path="/cashout/tickets" component={CashoutTickets} />
						{/* Results */}
						<WrappedRoute subject="results" path="/results/events" component={Results} />
						<WrappedRoute subject="results" path="/results/playerProps" component={PlayerPropsResults} />
						{/* Reports */}
						<WrappedRoute subject="reporting" path="/reports/cash-flow" component={CashFlow} />
						<WrappedRoute subject="reporting" path="/reports/basic-report" component={BasicFlow} />
						<WrappedRoute subject="reporting" path="/reports/tickets" component={TicketView} />
						<WrappedRoute subject="reporting" path="/reports/event-stats" component={EventStats} />
						<WrappedRoute subject="reporting" path="/reports/promotions-report" component={PromotionsReports} />
						<WrappedRoute
							subject="reporting"
							path="/reports/payment-transactions"
							component={PaymentTransactions}
						/>
						<WrappedRoute subject="reporting" path="/reports/tickets-report" component={TicketsReport} />
						<WrappedRoute subject="reporting" path="/reports/reservations" component={Reservations} />
						<WrappedRoute subject="reporting" path="/reports/verification" component={Verification} />
						<WrappedRoute subject="reporting" path="/reports/transactions" component={TransactionsReport} />
						<WrappedRoute subject="reporting" path="/reports/online-report" component={OnlineReport} />
						<WrappedRoute subject="reporting" path="/reports/aml-report" component={AMLReport} />
						{/* Parameters */}
						<WrappedRoute subject="risk" path="/parameters/limits" component={Limits} />
						<WrappedRoute subject="risk" path="/parameters/conditions" component={Conditions} />
						<WrappedRoute subject="risk" path="/parameters/authorization" component={AuthorizationParams} />
						<WrappedRoute path="/parameters/geo" component={Geo} bypassCasl />
						{/* Online Support  */}
						<WrappedRoute subject="support" path="/online-support/users" component={OnlineSupportUsers} />
						<WrappedRoute subject="promo" path="/online-support/banners" component={Banners} />
						<WrappedRoute
							subject="support"
							path="/online-support/templates"
							component={TemplatesAdministrations}
						/>
						{/* Betmedia */}
						<WrappedRoute subject="live" path="/betmedia/unassigned" component={Unassigned} />
						<WrappedRoute subject="live" path="/betmedia/devices" component={Devices} />
						{/* System */}
						<WrappedRoute path="/system/integrations" component={SystemIntegrations} subject="offer" />
						<WrappedRoute path="/system/roles" component={RolesTemplates} subject="superuser" />
						<WrappedRoute path="/system/users" component={BackofficeUsers} subject="superuser" />
						<WrappedRoute path="/system/logging" component={Logging} subject="superuser" />
						<WrappedRoute path="/system/settings" component={Settings} />
						<Route
							path="/forbiden"
							component={() => {
								const { logout } = useUser();
								logout();
								return <Redirect to="/login" />;
							}}
						/>
						<Route path="*" component={Dashboard} />
					</Switch>
				</Suspense>
			</MainLayout>
		</Router>
	);
};
